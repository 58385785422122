import { useCommonStore } from '@/store/common';
import { toFormat } from '@/utils/number';
import moment from 'moment-timezone';
import { findLastIndex } from 'lodash';
import BigNumber from 'bignumber.js';
import { isApp, req } from '@/utils/appBridge';
import axios from '@/utils/axios';
import html2canvas from 'html2canvas';

const common = useCommonStore();

// 获取货币展示精度
export function tokenShowPrecision(assetId: string) {
  let list = [];
  try {
    list = JSON.parse(sessionStorage.getItem('currencyList'));
  } catch {
    list = [];
  }
  const  currencyList = common.currencys?.length > 0 ? common.currencys : list;
  const token = currencyList.find((item: any) => item.tokenId === assetId) || {};
  if (Object.keys(token).length === 0) {
    return 2; // 如果没找到，默认展示两位小数
  } else {
    return token.showPrecision ?? 2; // 如果token内该字段为 null 或 undefined 默认返回 2
  }
}
// 货币以自身展示精度在页面展示
export function displayTokenByShowPrecison(amount: string | number, assetId: string, fmt?: BigNumber.Format) {
  const precision = tokenShowPrecision(assetId);
  return toFormat(amount, precision, fmt);
}
// 金额输入校验限制正则,num->保留多少小数位
export function inputNumReg(value: any, num: number) {
  if (typeof value == 'undefined' || value == '' || value == null) return '';
  if (value[0] == '0' && value?.[1] != '.') {
    value = '0';
    return value;
  }
  value = String(value).replace(/[^\d.]/g, '').replace(/^\./g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  if (value.indexOf('.') > 0) {
    const arr = value.split('.');
    if (num && num > 0) {
      value = `${arr[0]}.${arr[1]?.substr(0, num)}`;
    } else {
      value = arr[0];
    }
  }
  return value;
}
// 获取币种信息（图片iconUrl,tokenFullName）
export function showTokenInfo(assetId: string) {
  let list = [];
  try {
    list = JSON.parse(sessionStorage.getItem('currencyList'));
  } catch {
    list = [];
  }
  const  currencyList = common.currencys?.length > 0 ? common.currencys : list;
  const token = currencyList.find((item: any) => item.tokenId === assetId) || {};
  return token;
}

// 格式化数据，为整数部分添加逗号以提高可读性，小数部分保持不变
export function formatIntNumber(number: string | number) {
  if (!number) return number;
  const numb = String(number);
  let integerPart = '';
  let decimalPart = '';
  if (numb.includes('.')) {
    integerPart = numb.split('.')[0];
    decimalPart = numb.split('.')[1];
  } else {
    integerPart = numb;
    decimalPart = '';
  }
  // 为整数部分添加分隔符
  const data = new Intl.NumberFormat('en-US').format(parseInt(integerPart));
  // 返回数据
  if (numb.includes('.')) {
    return String(data) + '.' + decimalPart;
  }
  return String(data);
}

// 时间格式化
export function formatDate(time: string | number, onlyData = false) {
  if (!time) return '-';
  if (onlyData) {
    return moment(time).format('YYYY-MM-DD');
  } else {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
}

// 将中间地址以省略号代替, 地址默认展示前10后5
export function addressWithDots(address: string, left = 10, right = 5) {
  let str = '';
  if (address.length > (left + right)) {
    str = address.substring(0, left) + '...' + address.substring(address.length - right);
  } else {
    str = address;
  }
  return str;
}

/**
 * 将字符串根据换行符 \n \\n分割为数组
 * @param text 原数据
 * @param allowMiddleEmpty 是否保留文本中间的额外换行不过滤
 * @returns
 */
export function splitStrWithEnter(text: string, allowMiddleEmpty = false) {
  const regex = /\\n|\n/;
  // console.log('text,', text);
  const contentArr: any[] = (text || '').split(regex);
  const firstContentIdx = contentArr.findIndex(text => text);
  const lastContentIdx = findLastIndex(contentArr, (text: any) => text);
  // 保留中间的换行，去除头尾
  return allowMiddleEmpty ? contentArr.slice(firstContentIdx, lastContentIdx !== -1 ? lastContentIdx + 1 : contentArr.length) : ((text || '').split(regex).filter(text => text) || []);
}

export function formatMessageDate(timestamp: number) {
  const nowDate = moment(new Date());
  const msgDate = moment(timestamp);

  if (nowDate.isSame(msgDate, 'day')) {
    return msgDate.format('HH:mm');
  } else if (nowDate.isSame(msgDate, 'year')) {
    return msgDate.format('MM/DD');
  } else {
    return msgDate.format('YYYY/MM/DD');
  }
}

// 数字格式化并去0
export function decimalPlaces(value: any, places = 2) {
  return BigNumber(value).decimalPlaces(places).toFormat();
}

// 抹平浏览器和APP请求接口的差异，统一使用这一个方法进行接口请求
export const xApiRequest = {
  get: (url: string, params?: Record<string, unknown>, options?: Record<string, unknown>) => {
    if (isApp) {
      return req.get(url.replace(/^@/, '/'), params, options);
    }
    return axios.get(url, { params });
  },
  post: (url: string, data?: Record<string, unknown>, options?: Record<string, unknown>) => {
    if (isApp) {
      return req.post(url.replace(/^@/, '/'), data, options);
    }
    return axios.post(url, data);
  }
};

// 获取 html 转换成图片的 url
export const html2url = (el: HTMLElement) => {
  return new Promise<string>(resolve => {
    if (!el) {
      return resolve('');
    }

    html2canvas(el, {
      useCORS: true,
      allowTaint: true,
      backgroundColor: null
    }).then(canvas => {
      resolve(canvas.toDataURL());
    }).catch(err => {
      console.log(err);
      resolve('');
    });
  });
};
