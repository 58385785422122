import { assetApi } from '@/config/api';
// import { wsUrl } from '@/config/ws';
import Config from '@/config/site';
// import WS from '@/utils/ws_1.0';
import moment from 'moment-timezone';
import { useWsStore } from '@/store/ws';
import { useCommonStore } from '@/store/common';
import { toFormat, div, times, toFixed, minus, gt } from '@/utils/number';
import { pinia } from './index';
import { floatToPrecisionInt } from '@/utils/tools';
import { toast } from '@/utils/toast';
import { i18n } from '@/lang/i18n';
const { t } = i18n.global;

// store名称
const counter = useCommonStore(pinia);

export const useOrderStore = defineStore('orderStore', {
  state: () => ({
    // 订单ws
    // orderWs: <any>null,
    // 当前订单
    orderList: [] as any [],
    orderTime: 0,
    // 当前委托订单
    planOrderList: [] as any [],
    planOrderTime: 0,
    // 历史订单
    historyOrderList: [] as any [],
    // 历史委托订单
    historyPlanOrderList: [] as any [],
    historyRradesList: [] as any [],
    historyRradesTime: 0,
    // 资产列表
    assetList: [] as any [],
    // 预存金列表
    prefundList: [] as any [],
    // 资产时间控制
    assetTime: 0,
    // 是否是全部交易对
    isAllSymbol: false,
    orderParams: <any>{},
    planOrderParams: <any>{},
    orderHistoryParams: <any>{},
    planOrderHistoryParams: <any>{},
    orderWsParams: <any>null, // 存储当前order ws params
    orderHistoryWsParams: <any>null, // 存储当前order ws params
    getOrderTimer: <any>null, // get order接口的timer
    orderListLoading: false,
    historyListLoading: false,
    tradeListLoading: false,
  }),

  getters: {
    // 当前订单
    formatOrder(state: any) {
      if (!state.orderList.length) {
        return [];
      }

      const arr = state.orderList.map((item: any) => {
        const symbol = counter.symbolMap[item.symbolId];
        // 价格精度
        const pricePrecision = symbol?.minPricePrecision ? floatToPrecisionInt(symbol?.minPricePrecision) : 8;
        const basePrecision = symbol?.basePrecision ? floatToPrecisionInt(symbol?.basePrecision) : 8;
        const degreeCompletion = item.type === 'MARKET' && item.side === 'BUY'
          ? '--'
          : `${toFixed(times(div(item.executedQty, item.origQty), 100), 2)}%`;
        const notFinished = item.type === 'MARKET'
          ? '--'
          : `${toFormat(minus(item.origQty, item.executedQty), basePrecision)} ${item.baseTokenName}`;

        const obj = {
          orderId: item.orderId,
          // 时间
          timeStamp: Number(item.time),
          time: moment.utc(Number(item.time)).local().format('YYYY/MM/DD HH:mm:ss'),
          // 交易对
          symbol: `${item.baseTokenName}/${item.quoteTokenName}`,
          // 类别
          type: item.type,
          // 方向
          side: item.side,
          // 价格
          price: item.type === 'MARKET'
            ? 'MARKET'
            : `${toFormat(item.price, pricePrecision)} ${item.quoteTokenName}`,
          // 已成交
          executedQty: `${toFormat(item.executedQty, basePrecision)} ${item.baseTokenName}`,
          // 订单数量
          origQty: item.type === 'MARKET' && item.side === 'BUY'
            ? '--'
            : `${toFormat(item.origQty, basePrecision)} ${item.baseTokenName}`,
          // 完成度
          degreeCompletion,
          // 未完成
          notFinished
        };

        return obj;
      }).sort((a: any, b: any) => {
        return b.timeStamp - a.timeStamp;
      });

      return arr;
    },

    // 当前委托订单
    formatPlanOrder(state: any) {
      if (!state.planOrderList.length) {
        return [];
      }

      const arr = state.planOrderList.map((item: any) => {
        const symbol = counter.symbolMap[item.symbolId];
        const pricePrecision =  symbol?.minPricePrecision ? floatToPrecisionInt(symbol?.minPricePrecision) : 8;
        const quotePrecision = symbol?.quotePrecision ? floatToPrecisionInt(symbol?.quotePrecision) : 8;
        const basePrecision = symbol?.basePrecision ? floatToPrecisionInt(symbol?.basePrecision) : 8;
        const legal = Number(item.quotePrice) > Number(item.triggerPrice) ? '≤' : '≥';
        const executedAmount = item.type === 'MARKET' && item.side === 'BUY'
          ? `${toFormat(item.origQty, quotePrecision)} ${item.quoteTokenName}`
          : `${toFormat(times(item.price, item.origQty), quotePrecision)} ${item.quoteTokenName}`;
        const degreeCompletion = item.type === 'MARKET' && item.side === 'BUY'
          ? '--'
          : `${!Number(item.executedQty) ? 0 : toFixed(times(div(item.executedQty, item.origQty), 100), 2)}%`;
        const notFinished = item.type === 'MARKET'
          ? '--'
          : `${toFormat(minus(item.origQty, item.executedQty), basePrecision)} ${item.baseTokenName}`;

        const obj = {
          orderId: item.orderId,
          // 时间
          timeStamp: Number(item.time),
          time: moment.utc(Number(item.time)).local().format('YYYY/MM/DD HH:mm:ss'),
          // 交易对
          symbol: `${item.baseTokenName}/${item.quoteTokenName}`,
          // 类别
          type: item.type,
          // 方向
          side: item.side,
          // 触发价
          triggerPrice: `${legal}${toFormat(item.triggerPrice, pricePrecision)} ${item.quoteTokenName}`,
          // 委托价格
          price: item.type === 'MARKET'
            ? 'MARKET'
            : `${toFormat(item.price, pricePrecision)} ${item.quoteTokenName}`,
          // 委托数量
          origQty: item.type === 'MARKET' && item.side === 'BUY'
            ? '--'
            : `${toFormat(item.origQty, basePrecision)} ${item.baseTokenName}`,
          // 委托总额
          executed_amount: executedAmount,
          // 完成度
          degreeCompletion,
          notFinished
        };

        return obj;
      });

      arr.sort((a: any, b: any) => {
        return b.timeStamp - a.timeStamp;
      });

      return arr;
    },

    // 历史订单
    formatHistoryOrder(state: any) {
      if (!state.historyOrderList.length) {
        return [];
      }

      const arr = state.historyOrderList.map((item: any) => {
        const symbol = counter.symbolMap[item.symbolId];
        const pricePrecision = symbol?.minPricePrecision ? floatToPrecisionInt(symbol?.minPricePrecision) : 8;
        const quotePrecision = symbol?.quotePrecision ? floatToPrecisionInt(symbol?.quotePrecision) : 8;
        const basePrecision = symbol?.basePrecision ? floatToPrecisionInt(symbol?.basePrecision) : 8;
        const origQty = item.type === 'LIMIT' || (item.type == 'MARKET' && item.side == 'SELL')
          ? `${toFormat(item.origQty, basePrecision)} ${item.baseTokenName}`
          : `${toFormat(item.origQty, quotePrecision)} ${item.quoteTokenName}`;
        const state = item.status === 'CANCELED' && gt(item.executedQty, 0) ? 'PARTIALLY_FILLED' : item.status;

        // 前端判断市价单没有完全成交时修改状态为部分成交
        // if (item.status === 'FILLED' && item.type === 'MARKET' && gt(item.origQty, item.executedQty)) {
        //   state = 'PARTIALLY_FILLED';
        // }
        
        // 撤单原因
        let cancelReason = '';
        if (item.cancelReason) {
          switch (item.cancelReason) {
            case 0:
              cancelReason = t('系统撤单');
              break;
            case 1:
              cancelReason = t('主动撤单');
              break;
            case 2:
              cancelReason = t('系统撤单');
              break;
            case 3:
              cancelReason = t('风控撤单');
              break;
            case 4:
              cancelReason = t('封禁撤单');
              break;
            case 5:
              cancelReason = t('闭市撤单');
              break;
            case 6:
              cancelReason = t('风控撤单');
              break;
            case 7:
              cancelReason = t('下架撤单');
              break;
            case 8:
              cancelReason = t('/');
              break;
            case 9:
              cancelReason = t('风控撤单');
              break;
            case 10:
              cancelReason = t('风控撤单');
              break;
            case 11:
              cancelReason = t('IOC(限价)撤单');
              break;
            case 12:
              cancelReason = t('FOK(限价)撤单');
              break;
          }
        }

        const obj = {
          orderId: item.orderId,
          // 时间
          time: moment.utc(Number(item.time)).local().format('YYYY/MM/DD HH:mm:ss'),
          // 交易对
          symbol: `${item.baseTokenName}/${item.quoteTokenName}`,
          // 类别
          type: item.type,
          // 方向
          side: item.side,
          // 价格
          price: item.type === 'MARKET'
            ? 'MARKET'
            : `${toFormat(item.price, pricePrecision)} ${item.quoteTokenName}`,
          // 成交均价
          avgPrice: `${toFormat(item.avgPrice, pricePrecision)} ${item.quoteTokenName}`,
          // 数量
          origQty,
          executedQty: `${toFormat(item.executedQty, basePrecision)} ${item.baseTokenName}`,
          executedAmount: `${toFormat(item.executedAmount, quotePrecision)} ${item.quoteTokenName}`,
          status: state,
          // 撤单原因
          cancelReason: cancelReason,
          reasonCode: item.cancelReason
        };

        return obj;
      });

      return arr;
    },

    // 历史委托订单
    formatHistoryPlanOrder(state: any) {
      if (!state.historyPlanOrderList.length) {
        return [];
      }

      const arr = state.historyPlanOrderList.map((item: any) => {
        const symbol = counter.symbolMap[item.symbolId];
        const pricePrecision =  symbol?.minPricePrecision ? floatToPrecisionInt(symbol?.minPricePrecision) : 8;
        const quotePrecision = symbol?.quotePrecision ? floatToPrecisionInt(symbol?.quotePrecision) : 8;
        const basePrecision = symbol?.basePrecision ? floatToPrecisionInt(symbol?.basePrecision) : 8;
        const legal = Number(item.quotePrice) > Number(item.triggerPrice) ? '≤' : '≥';
        const origQty = item.type === 'LIMIT' || (item.type == 'MARKET' && item.side == 'SELL')
          ? `${toFormat(item.origQty, basePrecision)} ${item.baseTokenName}`
          : `${toFormat(item.origQty, quotePrecision)} ${item.quoteTokenName}`;
        const executedQty = item.type === 'MARKET' && item.side === 'BUY'
          ? `${toFormat(item.origQty, quotePrecision)} ${item.quoteTokenName}`
          : `${toFormat(times(item.price, item.origQty), quotePrecision)} ${item.quoteTokenName}`;

        const obj = {
          orderId: item.orderId,
          // 时间
          time: moment.utc(Number(item.time)).local().format('YYYY/MM/DD HH:mm:ss'),
          // 交易对
          symbol: `${item.baseTokenName}/${item.quoteTokenName}`,
          // 类别
          type: item.type,
          // 方向
          side: item.side,
          // 触发价格
          triggerPrice: `${legal}${toFormat(item.triggerPrice, pricePrecision)} ${item.quoteTokenName}`,
          // 委托价格
          price: item.type === 'MARKET'
            ? '--'
            : `${toFormat(item.price, pricePrecision)} ${item.quoteTokenName}`,
          // 委托数量
          origQty,
          // 委托总额
          executedQty,
          // 触发时间
          triggerTime: Number(item.triggerTime)
            ? moment.utc(Number(item.triggerTime)).local().format('YYYY/MM/DD HH:mm:ss')
            : '--',
          status: item.status
        };

        return obj;
      });

      return arr;
    },

    // 成交明细
    formatHistoryRradesList(state) {
      if (!state.historyRradesList.length) {
        return [];
      }

      const arr = state.historyRradesList.map((item: any) => {
        const symbol = counter.symbolMap[item.symbolId];
        const pricePrecision =  symbol?.minPricePrecision ? floatToPrecisionInt(symbol?.minPricePrecision) : 8;
        const basePrecision = symbol?.basePrecision ? floatToPrecisionInt(symbol?.basePrecision) : 8;
        const obj = {
          // 时间
          timeStamp: Number(item.time),
          time: moment.utc(Number(item.time)).local().format('YYYY/MM/DD HH:mm:ss'),
          symbol: `${item.baseTokenName}/${item.quoteTokenName}`,
          type: item.type,
          side: item.side,
          price: `${toFormat(item.price, pricePrecision)} ${item.quoteTokenName}`,
          quantity: `${toFormat(item.quantity, basePrecision)} ${item.baseTokenName}`,
          baseToken: `${toFormat(times(item.price, item.quantity), 8)} ${item.quoteTokenName}`,
          fee: item.makerRebate === '0' ? `${toFixed(item.fee, 8)} ${item.feeTokenName}` : `-${toFixed(item.makerRebate, 8)} ${item.feeTokenName}`,
          isHsk: item?.hskDeduct ? t('是') : t('否'), // HSK抵扣
          hskDeductPrice: (item?.hskDeduct && item?.hskDeductPrice) ? toFormat(item?.hskDeductPrice, 8) : '--', // XXX/HSK兑换价格
          hskDeduct: item.hskDeduct,
          quoteTokenName: item.quoteTokenName,
          feeTokenName: item.feeTokenName,
          originFeeCoinId: item.originFeeCoinId,
          tradeId: item.tradeId,
          isNormal: (item.isNormal == null || item.isNormal == undefined) ? true : item?.isNormal,
          isMaker: item.isMaker
        };

        return obj;
      });
      arr.sort((a: any, b: any) => {
        return b.timeStamp - a.timeStamp;
      });

      return arr;
    },

    // 资产信息
    getAssetInfo(state) {
      const { assetList, prefundList } = state;
      const { currencys } = counter;
      const obj: any = {
        allCurrencys: [], // 所有币种
        withAssets: [], // 带有资产的币种
        withAssetsMap: {} // 带有资产的币种map
      };

      // 没有币时
      if (!currencys || !currencys.length) {
        return obj;
      }

      // 没有资产时
      if (!assetList.length && !prefundList.length) {
        obj.allCurrencys = currencys;

        return obj;
      }
      // 余额
      const assetMap: any = {};

      assetList.forEach((item: any) => {
        assetMap[item.tokenName] = item;
      });
      // 预存金
      const prefundMap: any = {};

      prefundList.forEach((item: any) => {
        prefundMap[item.tokenName] = item;
      });

      currencys.forEach((item: any) => {
        if (assetMap[item.tokenName] || prefundMap[item.tokenName]) {
          item.asset = assetMap[item.tokenName] || {};
          item.prefund = prefundMap[item.tokenName] || {}; // 预存金
          obj.withAssets.push(item);
          obj.withAssetsMap[item.tokenName] = item;
        } else {
          item.asset = {};
        }

        obj.allCurrencys.push(item);
      });

      return obj;
    }
  },

  actions: {
    /**
     * 清除ws
     *
     * @param type 要清除的名称
     */
    closeWs(type: string) {
      const wsStore = useWsStore();
      if (!type) {
        return;
      }
      // this.orderWs && this.orderWs.cancel(type);
      wsStore.userWS && wsStore.unSub(type, wsStore.userWS);
    },
    /**
     * 创建ws
     *
     * @param type 要请求的名称
     * @param param 请求的参数
     */
    newOrderWs(type: string, param: any = {}) {
      // if (!this.orderWs) {
      //   this.orderWs = new WS(wsUrl.orderWs);
      // }
      const wsStore = useWsStore();
      if (!wsStore.userWS) {
        wsStore.createWS('user');
      }

      if (type === 'asset') {
        this.getAssetWs();
      }

      if (type === 'openOrders') {
        this.orderWsParams = param;
        this.getOrderWs(param);
      }

      if (type === 'openPlanOrders') {
        this.getPlanOrderWs(param);
      }

      if (type === 'historyRrades') {
        this.orderHistoryWsParams = param;
        this.getHistoryRradesWs(param);
      }

    },

    /**
     * 请求订单
     *
     * @param param 请求的参数
     * @param type  来源
     */
    getOrder(param: any, type = '') {
      this.orderListLoading = true;
      this.orderWsParams = param; // 更新order的查询条件
      this.orderParams = {
        ...param,
        source: type
      };
      assetApi.openOrders(param)
        .then((data: any) => {
          this.orderListLoading = false;
          if (data.code === 200) {
            this.orderList = data.data;
          } else {
            if (data?.error?.response?.data?.code != 30000) {
              // 非鉴权报错
              toast.error(data.message);
            }
          }
        })
        .catch((error:any) => {
          this.orderListLoading = false;
          console.log(error);
        });
    },

    /**
     * 处理订单ws推送的数据
     *
     * @param data rs推送的数据
     */
    handleOrderWsData(data: any, param: any) {
      data.forEach((item: any) => {
        if (item.status === 'NEW') { // 新订单
          // 判断来源是订单页
          if (this.orderParams.source) {
            // 判断是第一页时
            if (!this.orderParams.from_order_id && !this.orderParams.end_order_id) {
              this.getOrder(this.orderParams);
            }
          } else {
            // 判断是否传交易对了
            if (this.orderParams.symbol_id) {
              if (this.orderParams.symbol_id === item.symbolId) {
                this.orderList.unshift(item);
              }
            } else {
              this.orderList.unshift(item);
            }
          }
        } else if (item.status === 'PARTIALLY_FILLED') { // 部份成交
          let isExist = false;
          this.orderList && this.orderList.forEach((it: any, i: number) => {
            if (it.orderId === item.orderId) {
              this.orderList[i] = item;
              isExist = true;
            }
          });

          // 判断是现货的订单页面
          if (!this.orderParams.source && !isExist) {
            // 判断是否传交易对了
            if (this.orderParams.symbol_id) {
              if (this.orderParams.symbol_id === item.symbolId) {
                this.orderList.unshift(item);
              }
            } else {
              this.orderList.unshift(item);
            }
          }
        } else {
          // 已成交的在已有列表中删除
          let n = -1;
          this.orderList && this.orderList.forEach((it: any, i: number) => {
            if (it.orderId === item.orderId) {
              n = i;
            }
          });

          if (n >= 0) {
            if (this.orderParams.source) {
              // 判断已请求过
              this.orderHistoryParams.limit && this.getHistoryOrderList(this.orderHistoryParams);
            } else {
              this.orderList.splice(n, 1);
            }
            // 5475 判断当前委托列表是否为空，为空刷调接口刷新列表
            if (this.orderList && this.orderList.length < 50) {
              if (this.getOrderTimer) {
                clearTimeout(this.getOrderTimer);
                this.getOrderTimer = null;
              }
              this.getOrderTimer = setTimeout(() => { this.getOrder(this.orderWsParams); }, 1000);
            }
          }

          // 历史订单在有的替换
          this.historyOrderList.length && this.historyOrderList.forEach((it: any, i: number) => {
            if (it.orderId === item.orderId) {
              this.historyOrderList[i] = item;
            }
          });
        }
      });
    },

    /**
     * 请求订单ws
     *
     * @param param 请求的参数
     */
    getOrderWs(param: any) {
      const wsStore = useWsStore();
      wsStore.userWS?.sub(
        {
          id: 'order',
          topic: 'order',
          event: 'sub',
          params: {
            org: Config.orgId,
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => {
          this.getOrderTimeout(param);
        },
        (data: any) => {
          if (data.data && data.data.length) {
            this.handleOrderWsData(data.data, param);
          }
        }
      );
    },

    /**
     * 请求订单轮循
     *
     * @param param 请求的参数
     */
    getOrderTimeout(param: any) {
      param = this.orderWsParams || param;
      clearTimeout(this.orderTime);
      this.orderTime = setTimeout(() => {
        this.getOrder(param);
        this.getOrderTimeout(param);
      }, 5000);
    },
    /**
     * 清除订单轮循
     */
    clearOrder() {
      clearTimeout(this.orderTime);
    },

    /**
     * 请求委托订单
     *
     * @param param 请求的参数
     * @param type  来源
     */
    getPlanOrder(param: any, type = '') {
      this.planOrderParams = {
        ...param,
        source: type
      };
      // assetApi.openPlanOrders(param)
      //   .then((data: any) => {
      //     if (data.code === 200) {
      //       this.planOrderList = data.data;
      //     }
      //   })
      //   .catch((error:any) => {
      //     console.log(error);
      //   });
    },

    /**
     * 处理计划委托订单ws推送的数据
     *
     * @param data rs推送的数据
     */
    handlePlanOrderWsData(data: any) {
      if (!data || !data.length) {
        return;
      }

      data.forEach((item: any) => {
        if (item.status === 'ORDER_NEW') {
          // 判断来源是订单页
          if (this.planOrderParams.source) {
            // 判断是第一页时
            if (!this.planOrderParams.from_order_id && !this.planOrderParams.end_order_id) {
              this.getPlanOrder(this.planOrderParams);
            }
          } else {
            if (this.planOrderParams.symbol_id) {
              if (this.planOrderParams.symbol_id === item.symbolId) {
                this.planOrderList.unshift(item);
              }
            } else {
              this.planOrderList.unshift(item);
            }
          }
        } else {
          let n = -1;
          this.planOrderList && this.planOrderList.forEach((it: any, i: number) => {
            if (it.orderId === item.orderId) {
              n = i;
            }
          });

          // 判断当条数据是在列表中的
          if (n >= 0) {
            if (this.orderParams.source) {
              // 判断已请求过
              this.planOrderHistoryParams.limit
                && this.getHistoryPlanOrderList(this.planOrderHistoryParams);
            }
            // else {
            //   this.orderList.splice(n, 1);
            // }
            this.planOrderList.splice(n, 1);
          }

          this.historyPlanOrderList.length && this.historyPlanOrderList.forEach((it: any, i: number) => {
            if (it.orderId === item.orderId) {
              this.historyPlanOrderList[i] = item;
            }
          });
        }
      });
    },

    /**
     * 请求委托订单ws
     *
     * @param param 请求的参数
     */
    getPlanOrderWs(param: any) {
      // this.orderWs.sub(
      //   {
      //     id: 'plan_order',
      //     topic: 'plan_order',
      //     event: 'sub',
      //     params: {
      //       org: Config.orgId,
      //       binary: import.meta.env.MODE === 'production'
      //     }
      //   },
      //   () => {
      //     this.getTimeoutPlanOrder(param);
      //   },
      //   (data: any) => {
      //     if (data.data && data.data.length) {
      //       this.handlePlanOrderWsData(data.data);
      //     }
      //   }
      // );
    },

    /**
     * 请求委托订单轮循
     *
     * @param param 请求的参数
     */
    getTimeoutPlanOrder(param: any) {
      clearTimeout(this.planOrderTime);
      this.planOrderTime = setTimeout(() => {
        this.getPlanOrder(param);
        this.getTimeoutPlanOrder(param);
      }, 5000);
    },

    /**
     * 清除委托订单轮循
     *
     * @param param 请求的参数
     */
    clearPlanOrder() {
      clearTimeout(this.planOrderTime);
    },

    /**
     * 请求历史订单
     *
     * @param param 请求的参数
     * @param type  来源
     */
    getHistoryOrderList(param: any, type = '') {
      this.historyListLoading = true;
      this.orderHistoryParams = {
        ...param,
        source: type
      };
      if (type) {
        this.planOrderHistoryParams.source = type;
      }
      assetApi.historyOrders(param)
        .then((data: any) => {
          this.historyListLoading = false;
          if (data.code === 200) {
            this.historyOrderList = data.data;
          } else {
            if (data?.error?.response?.data?.code != 30000) {
              // 非鉴权报错
              toast.error(data.message);
            }
          }
        })
        .catch((error:any) => {
          this.historyListLoading = false;
          console.log(error);
        });
    },

    /**
     * 请求委托历史订单
     *
     * @param param 请求的参数
     * @param type  来源
     */
    getHistoryPlanOrderList(param: any, type = '') {
      this.planOrderHistoryParams = {
        ...param,
        source: type
      };
      // assetApi.historyPlanOrders(param)
      //   .then((data: any) => {
      //     if (data.code === 200) {
      //       this.historyPlanOrderList = data.data;
      //     }
      //   })
      //   .catch((error:any) => {
      //     console.log(error);
      //   });
    },

    /**
     * 请求成交明细
     *
     * @param param 请求的参数
     */
    getHistoryRrades(param: any) {
      this.tradeListLoading = true;
      assetApi.historyRrades(param)
        .then((data: any) => {
          this.tradeListLoading = false;
          if (data.code === 200) {
            this.historyRradesList = data.data;
          } else {
            if (data?.error?.response?.data?.code != 30000) {
              // 非鉴权报错
              toast.error(data.message);
            }
          }
        })
        .catch((error:any) => {
          this.tradeListLoading = false;
          console.log(error);
        });
    },

    /**
     * 处理成交明细ws推送的数据
     *
     * @param data rs推送的数据
     */
    handleHistoryRradesWsData(data: any) {
      if (!data || !data.length) {
        return;
      }
      let oSwitch = false;
      data.forEach((item: any) => {
        this.historyRradesList.length && this.historyRradesList.forEach((it: any, i: number) => {
          if (it.orderId === item.orderId) {
            this.historyRradesList[i] = item;
            oSwitch = true;
          }
        });

        if (!oSwitch) {
          this.historyRradesList.push(item);
        }
      });
    },

    /**
     * 请求成交明细ws
     *
     * @param param 请求的参数
     */
    getHistoryRradesWs(param: any) {
      const wsStore = useWsStore();
      wsStore.userWS?.sub(
        {
          id: 'match',
          topic: 'match',
          event: 'sub',
          params: {
            org: Config.orgId,
            binary: import.meta.env.MODE === 'production'
          }
        },
        () => {
          this.getTimeoutHistoryRrades(param);
        },
        (data: any) => {
          if (data.data && data.data.length) {
            this.handleHistoryRradesWsData(data.data);
          }
        }
      );
    },

    /**
     * 请求成交明细轮循
     *
     * @param param 请求的参数
     */
    getTimeoutHistoryRrades(param: any) {
      param = this.orderHistoryWsParams || param;
      clearTimeout(this.historyRradesTime);
      this.historyRradesTime = setTimeout(() => {
        this.getHistoryRradesWs(param);
        this.getTimeoutHistoryRrades(param);
      }, 5000);
    },

    /**
     * 请除成交明细轮循
     *
     * @param param 请求的参数
     */
    clearHistoryRrades() {
      clearTimeout(this.historyRradesTime);
    },

    /**
     * 币余额查询
     */
    getAsset(userType = 0) {
      // 可用余额
      assetApi.getAsset()
        .then((data: any) => {
          if (data.success) {
            this.assetList = data.data;
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
      // 预存金余额 (4综合账户, 16授权管理员账户)
      if (userType && [4, 16].includes(userType)) {
        assetApi.getAsset({ account_type: 9 })
          .then((data: any) => {
            if (data.success) {
              this.prefundList = data.data;
            }
          })
          .catch((error: any) => {
            console.log(error);
          });
      }
    },

    /**
     * 币余额查询ws
     */
    getAssetWs() {
      const wsStore = useWsStore();
      wsStore.userWS?.sub(
        {
          id: 'balance',
          topic: 'balance',
          event: 'sub'
        },
        () => {
          this.setTimeoutAsset();
        },
        (data: any) => {
          if (data.data && data.data.length) {
            // 余额
            const obj: any = {};
            let bool = false;
            // 预存金
            const preObj: any = {};
            let preBool = false;

            data.data.forEach((item: any) => {
              if (item?.accountType == 59) {
                // 预存金
                preObj[item.tokenId] = item;
              } else {
                // 余额
                obj[item.tokenId] = item;
              }
            });
            // 余额
            this.assetList.forEach((item: any, index: number) => {
              if (obj[item.tokenId]) {
                this.assetList[index] = obj[item.tokenId];
                bool = true;
              }
            });

            if (!bool) {
              Object.keys(obj).forEach((key: string) => {
                this.assetList.push(obj[key]);
              });
            }
            // 预存金
            this.prefundList.forEach((item: any, index: number) => {
              if (preObj[item.tokenId]) {
                this.prefundList[index] = preObj[item.tokenId];
                preBool = true;
              }
            });

            if (!preBool) {
              Object.keys(preObj).forEach((key: string) => {
                this.prefundList.push(preObj[key]);
              });
            }

          }
        }
      );
    },

    /**
     * 币余额查询timeout
     */
    setTimeoutAsset() {
      clearTimeout(this.assetTime);
      this.assetTime = setTimeout(() => {
        this.getAsset();
        this.setTimeoutAsset();
      }, 5000);
    },

    /**
     * 清除timeout
     */
    clearAssetTime() {
      clearTimeout(this.assetTime);
    }
  }
});
